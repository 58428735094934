import * as React from 'react';

import Image from 'next/image';

import { DownloadAppButton } from '@/components/common';

export const InsightsNutritionCta: React.FC = () => (
  <div className='px-6 lg:px-12 lg:max-w-7xl mx-auto mt-6 lg:mt-8 pt-8'>
    <div className='bg-khaki-1 rounded-2xl gap-x-8 relative lg:justify-around xl:justify-between items-center lg:flex lg:before:absolute lg:before:w-full lg:before:h-[278px] xl:before:h-[358px] lg:bg-transparent lg:before:bg-khaki-1 lg:before:rounded-lg lg:before:top-10 xl:before:top-12'>
      <div className='relative lg:order-last lg:z-10 lg:pr-12 before:w-full before:absolute before:h-[155px] before:bg-gradient-purple-to-blue before:rounded-t-2xl lg:before:hidden sm:before:h-[233px] shrink-0'>
        <Image
          alt='Recipes'
          className='w-64 mx-auto z-10 relative -translate-y-[22px] lg:w-[460px] xl:w-[588px] lg:translate-y-0 sm:w-96 sm:-translate-y-8'
          height={454}
          src='/images/marketing-card/get-the-app.png'
          width={588}
        />
      </div>
      <div className='lg:w-[40%] lg:z-10 lg:pl-6 lg:pr-0 lg:mx-0 lg:pb-0 xl:pl-14 px-5 pb-7 max-w-md text-center mx-auto lg:text-left lg:max-w-md xl:max-w-none'>
        <div className='font-lato text-xs mb-1 xl:mb-2 tracking-widest uppercase font-bold bg-clip-text text-transparent bg-gradient-yellow-to-purple'>
          Introducing
        </div>
        <h2 className='font-lato tracking-tight font-bold text-2xl bg-clip-text text-transparent bg-gradient-yellow-to-purple xl:text-4xl'>
          Insights, nutrition, and recipes designed for you.
        </h2>
        <p className='mt-1 mb-3 xl:mb-4 xl:mt-2 leading-snug'>
          Download our app and get access to our brand new insights, nutrition suggestions, and recipes that will
          compliment your&nbsp;body.{' '}
        </p>
        <div className='flex flex-wrap gap-2 justify-center lg:justify-start'>
          <DownloadAppButton location='Insights and Nutrition CTA' os='iOS' />
          <DownloadAppButton location='Insights and Nutrition CTA' os='Android' />
        </div>
      </div>
    </div>
  </div>
);
