import * as React from 'react';

import { Billboard, DownloadAppButton, Hero, Highlight, SectionHeader } from '@/components/common';
import { FAQs, FourPhases, TheMethod, Testimonial } from '@/components/marketing';
import { Carousel, Divider } from '@/components/ui';
import { testimonials } from '@/data/testimonials';

export const MarketingHomepage = React.memo(() => {
  return (
    <>
      <div className='space-y-10 md:space-y-16'>
        <div className='[&_h1]:text-[40px] md:[&_h1]:text-6xl [&_h1]:font-normal [&_h1]:leading-[100%]'>
          <Hero
            copy="Get daily exercise videos, nutrition tips, and emotional insights based on your body's natural cycle."
            ctaText='Join Now'
            heading='#1 Cycle-Based Fitness + Wellness Experience'
          />
        </div>
        <div>
          <Highlight
            ctaText='Join for Free'
            heading='Workout With Your Cycle, Not Against It'
            icon='/icons/flower.svg'
            image='/images/highlight/workout-with-your-cycle.jpg'
            imageHeight={760}
            imageWidth={640}
            reverse={false}
          >
            <p>
              Many popular fitness methods were designed for men, not women. 28 is the first method of its kind designed
              to optimize your energy, hormones, strength, and flexibility as you experience changes throughout your
              cycle.
            </p>
          </Highlight>
          <Divider className='invisible my-2 md:visible lg:my-0' variant='wavy' />
          <Highlight
            ctaText='Join for Free'
            heading="Painful Periods Aren't Natural"
            headingGradient='purple-to-blue'
            icon='/icons/atoms.svg'
            image='/images/highlight/nature-tech.jpg'
            imageHeight={560}
            imageWidth={560}
            reverse={true}
          >
            <p>
              But they are common. Synthetic estrogens in birth control, plastics, and beauty products have created
              hormone imbalances for millions of women. 28&apos;s functional nutrition is designed to naturally restore
              balance to your hormones.
            </p>
          </Highlight>
          <Divider className='invisible my-2 md:visible lg:my-0' reverse={true} variant='wavy' />
          <Highlight
            ctaText='Join for Free'
            heading='Your Cycle is Your Compass'
            headingGradient='blue-to-green'
            icon='/icons/compass.svg'
            image='/images/highlight/your-cycle-is-your-compass.jpg'
            imageHeight={760}
            imageWidth={506}
            reverse={false}
          >
            <p>
              Your cycle has four phases. Each phase brings changes to the brain and body. Exercising and eating based
              on your cycle means changing the types of movements and nutrients during each phase to optimize your
              physique, hormones, and mood.
            </p>
          </Highlight>
          <Divider className='invisible my-2 md:visible lg:my-0' reverse={false} variant='wavy' />
          <Highlight
            ctaText=''
            heading='Get the App on iOS and Android'
            headingGradient='purple-to-blue'
            icon='/icons/meteor.svg'
            image='/images/highlight/get-the-app.jpg'
            imageHeight={760}
            imageWidth={506}
            reverse={true}
          >
            <p>
              Download the 28 app for free, available for iPhone and Android. Discover the science of your cycle and get
              personalized daily insights, nutrition, and exercise videos with lightening-fast, high-definition
              streaming.
            </p>
            <div className='text-purple flex flex-wrap gap-2'>
              <DownloadAppButton className='pl-4 pr-5' location='Highlight' os='iOS' />
              <DownloadAppButton className='pl-4 pr-5' location='Highlight' os='Android' />
            </div>
          </Highlight>
          {testimonials && (
            <Carousel containerWidth={105} fluidGutters={false} itemGap={16}>
              {testimonials.slice(1).map((testimonial) => (
                <Testimonial
                  author={testimonial.author}
                  authorBio={testimonial.authorBio}
                  key={testimonial.shortQuote}
                  quote={testimonial.quote}
                  shortQuote={testimonial.shortQuote}
                  variant='card'
                />
              ))}
            </Carousel>
          )}
        </div>
        <Billboard
          ctaText='Join for Free'
          heading='Let&rsquo;s introduce you to you.'
          image='/images/billboard/lets-introduce-you-to-you.jpg'
          variant='wide'
        />
        <SectionHeader
          description="You are a mystery. Your body doesn't have to be."
          image='/icons/atoms.svg'
          title='Your Four Phases'
        />
        <FourPhases />
        <Billboard
          ctaText='Join for Free'
          heading='Your body is a force of nature.'
          image='/images/billboard/your-body-is-a-force-of-nature.jpg'
          variant='wide'
        />
        <SectionHeader
          description='28 is a rejuvenating, stability-based approach to feminine fitness designed by supermodel trainers and medical experts to work with your natural cycle.'
          image='/icons/atoms.svg'
          title='The Method'
        />
        <TheMethod />
        <Billboard
          ctaText='Join for Free'
          heading='Become the expert of you.'
          image='/images/billboard/let-nature-be-your-guide.jpg'
          variant='wide'
        />
        <Testimonial
          author={testimonials[0].author}
          authorBio={testimonials[0].authorBio}
          image={testimonials[0].image}
          quote={testimonials[0].quote}
          variant='featured'
        />
        <Billboard
          ctaText='Join for Free'
          heading='Retake control of your body.'
          image='/images/billboard/retake-control-of-your-body.jpg'
          variant='wide'
        />
        <SectionHeader description='Our Most Frequently Asked Questions' title='F.A.Q.' />
        <FAQs />
      </div>
    </>
  );
});

MarketingHomepage.displayName = 'MarketingHomepage';
