import { FAQ } from './types';

export const faqs: FAQ[] = [
  {
    question: "Does 28 still have the free version even though there's Premium subscription now?",
    answer:
      'Yes! You can still enjoy majority of the original content under 28 free version and you can upgrade to Premium if you would like unlimited access to all workout videos, nutrition recipes, grocery list and many more upcoming new features!',
  },
  {
    question: 'What do you do with my personal data, especially around cycle tracking?',
    answer:
      'Protecting user data is our highest priority, which is why 28 is custom built with the most cutting-edge technology and services available today. We will never sell your personal information and we will protect it to the same extent we protect our most proprietary confidential information. The data we voluntarily collect is strictly confidential and used only for the purposes of providing a customized user experience.',
  },
  {
    question: 'How do I use the program?',
    answer: `Once you signup, enter your cycle information for the "last day of your first period" and "average cycle duration" or update them in app under Profile > My Cycle anytime. We encourage you to keep the information update to achieve the most accurate results. Every day upon logging in, you'll access a personalized dashboard that provides insights about your body, hormones and emotions. You'll find your workout videos for the day, nutrition profile and lifestyle tips - all customized to where you are in your cycle.`,
  },
  {
    question: 'How long does it take to do each workout?',
    answer:
      "Depending on the phase you're in, the average workout is approximately 15-35 minutes in length, with the average being close to 22 minutes.",
  },
  {
    question: 'What results can I expect from these workouts?',
    answer:
      'Physically: A stronger, more flexible body. Emotionally: Balanced hormones and more predictable mood swings. Aesthetically: These workouts are designed to lean your legs, tone your arms, sculpt your booty, and strengthen your core for a beautifully feminine physique.',
  },

  {
    question: 'How does the menstrual cycle impact training?',
    answer:
      "The menstrual cycle drastically changes your body, hormones, energy, and emotions throughout the month. Training based on these fluctuations ensures you're giving your body what it needs, when it needs it.",
  },
  {
    question: 'How does 28 track my menstrual cycle?',
    answer:
      "Upon signup, you'll be prompted document the first date of your last period and your average cycle duration. As you go through the program, you can adjust as needed if future periods come late or early by updating your cycle in your profile settings. Over time, you'll understand your body and its fluctuations more intimately, thanks to 28's easy-to-understand, bite-sized approach to breaking down the science of the cycle.",
  },
  {
    question:
      "Why do I sync my cycle to the last New Moon if I don't know when my last period was, or am currently not getting a period?",
    answer:
      "The Lunar Cycle is roughly the same number of days as the average menstrual cycle, which makes it a great substitute. Your body benefits from being put on a consistent schedule with how you exercise and what you eat. It's normal and expected for the phases to feel inaccurate at first when using the New Moon in place of your period, but keep at it! Over time, your body will adjust and improve.",
  },
  {
    question: 'Will these workouts help to relieve period cramps and other painful period symptoms?',
    answer:
      "While every body is different, most users will experience more manageable symptoms of PMS when following 28's nutrition guidelines, which are designed to help regulate hormones and provide key nutrients when it matters most.",
  },
  {
    question: 'Is this program good for women of all body types to use?',
    answer:
      'Yes! This method was designed to be effective, regardless of size or shape, and challenging for both beginners and experts.',
  },
  {
    question: 'Do I need any equipment for the workouts?',
    answer:
      "All the workouts can be done without any equipment! However, we recommend using a yoga mat if you're working out on a hard surface. If you're looking for an extra challenge, you can add resistance bands or hand/ankle weights to many of the workouts. But trust us, they're harder than they look!",
  },
  {
    question: "Can I do this program if I'm pregnant?",
    answer:
      'Some of the workouts in this program, especially core-focused workouts, are not safe for pregnancy. If you are pregnant, consult your doctor before starting this or any other exercise or nutrition program.',
  },
  {
    question: 'Is there an app for 28 or is it online only?',
    answer:
      'Yes, we have an app for iOS and Android! When you create a 28 account on this site, you can use those credentials to log into the app upon downloading it, and vice versa.',
  },
  {
    question: 'How do I cancel the subscription?',
    answer:
      'If you are using iOS, you can go to Settings > Tap your name > Subscriptions to cancel. If you are using Android, you can go to Google Play > Select the subscription > Cancel subscription.',
  },
];
